
import capitalize from '@/mixins/capitalize'
import { mapState } from 'vuex'
import authLogic from './authLogic'

export default {
    mixins: [authLogic, capitalize],

    props: {
        inputClasses: {
            type: String,
            default: '',
        },
        noFlip: Boolean,
        isVendor: Boolean,
        modal: Boolean,
    },

    data() {
        return {
            loading: false,
        }
    },

    computed: {
        ...mapState({
            globalTexts: (state) => state.jsonloader.data['global.json'] || null,
        }),

        subHeading() {
            if (this.isVendor) {
                return this.globalTexts?.loginVendor?.subtitle || ''
            } else if (this.isStyleQuiz) {
                return 'Sign in to see your results.'
            } else if (this.product?.id) {
                return 'Login to complete your payment.'
            } else {
                return this.globalTexts?.login?.subtitle || ''
            }
        },

        title() {
            return this.isVendor ? this.globalTexts?.loginVendor?.title : this.globalTexts?.login?.title
        },

        buttonLabel() {
            return this.isVendor ? this.globalTexts?.loginVendor?.button : this.globalTexts?.login?.button
        },
    },

    methods: {
        loginPassword() {
            if (this.$gtag && this.$gtag.event)
                this.$gtag.event('login', { method: 'Google', event_label: 'modal' })
            this.loading = true
            this.$store
                .dispatch('user/loginPassword', { username: this.model.email, password: this.model.password })
                .then((res) => {
                    this.doAfterLogin(res, this.isVendor)
                })
                .catch(() => {})
                .finally(() => {
                    this.loading = false
                })
        },
    },
}
