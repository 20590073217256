
import { mapState, mapGetters, mapMutations } from 'vuex'
import openable from '~/mixins/openable'

export default {
    mixins: [openable],

    props: {
        desktopScroll: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            mobile: undefined,
            modalShow: false,
            productModalShow: false,
            product: null,
            isLogin: true,
            scrolled: false,
            isSearch: false,
            isVendor: false,
            confirmModalShow: false,
            confirmModalTitle: '',
            confirmModalMessage: '',
            confirmModalCallback: undefined,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user,
        }),

        ...mapGetters({ isPremiumUser: 'user/isPremiumUser' }),

        currentPage() {
            return this.$route.name
        },
    },

    watch: {
        $route() {
            this.modalShow = false
            this.confirmModalShow = false
            this.isSearch = this.$route.name === 'search'
        },

        $auth: {
            handler(val) {
                if (val.loggedIn) {
                    this.modalShow = false
                    this.confirmModalShow = false
                }
            },
        },
    },

    mounted() {
        if (this.$route.name === 'search') this.isSearch = true

        this.$store.subscribe((m) => {
            if (m && m.type === 'board/triggerBoardModal') {
                if (!this.$auth.loggedIn) {
                    this.confirmModalShow = false
                    this.modalShow = true
                }
            }
        })

        this.$store.subscribe((m) => {
            if (m && m.type === 'products/triggerProductModal') {
                this.product = m.payload
                this.confirmModalShow = false
                this.setProduct(m.payload)
                if (this.product) {
                    if (!this.$auth.loggedIn) {
                        this.isLogin = false
                        this.modalShow = true
                    }
                    this.productModalShow = true
                }
            }
        })

        this.mobile = process.browser ? window.innerWidth < 992 : null

        if (this.$route.query.login) {
            this.modalShow = true
        }

        this.$nuxt.$on('open-login-modal', () => {
            this.isLogin = true
            this.isVendor = false
            this.modalShow = true
        })

        this.$nuxt.$on('open-register-modal', (isVendor = false) => {
            this.isVendor = isVendor
            this.isLogin = false
            this.modalShow = true
        })

        this.$nuxt.$on('open-confirm-modal', (title, msg, callback = undefined) => {
            console.log('Opening confirm modal', title, msg, callback)
            this.modalShow = false
            this.confirmModalTitle = title
            this.confirmModalMessage = msg
            this.confirmModalShow = true
            this.confirmModalCallback = callback
        })

        window.addEventListener('scroll', this.handleOffsetTopChange)
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleOffsetTopChange)
    },

    methods: {
        ...mapMutations({
            setProduct: 'products/setItem',
        }),

        handleOffsetTopChange() {
            if (this.desktopScroll || this.mobile) this.scrolled = document.documentElement.scrollTop > 0
        },

        closeMegaMenuItem() {
            this.$refs.megaMenu?.closeMegaMenuItem(true)
        },

        toggleVendorModal(isLogin = false) {
            this.modalShow = false
            this.$nextTick(() => {
                setTimeout(() => {
                    this.isLogin = isLogin
                    this.isVendor = !this.isVendor
                    this.modalShow = true
                }, 200)
            })
        },

        confirmModalCallbackFunc() {
            if (this.confirmModalCallback)
                this.confirmModalCallback()
            this.confirmModalShow = false
        },
    },
}
