import { render, staticRenderFns } from "./VendorMenuItems.vue?vue&type=template&id=5f54c246&lang=pug"
import script from "./VendorMenuItems.vue?vue&type=script&lang=js"
export * from "./VendorMenuItems.vue?vue&type=script&lang=js"
import style0 from "./VendorMenuItems.vue?vue&type=style&index=0&id=5f54c246&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyAvatar: require('/app/components/elements/Avatar.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default})
