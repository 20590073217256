
import siteMixin from '@/mixins/siteMixin'

export default {
    name: 'VendorLayout',

    mixins: [siteMixin],

    mounted() {
        document.querySelector('body').classList.add('overflow-y-scroll')
    },

    destroyed() {
        document.querySelector('body').classList.remove('overflow-y-scroll')
    },
}
