
import { mapState } from 'vuex'
import mobileScroll from '@/mixins/mobileScroll'

export default {
    mixins: [mobileScroll],

    props: {
        isVendor: Boolean,
    },

    data() {
        return {
            gap: 32,
        }
    },

    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),

        menu() {
            return this.isVendor
                ? [
                      { name: 'Dashboard', icon: 'home', to: { name: 'vendor-dashboard' } },
                      { name: 'My Tasks', icon: 'check-circle', to: { name: 'vendor-checklist' } },
                      { name: 'Profile', icon: 'users', to: { name: 'vendor-management-step', params: { step: 1 } } },
                      { name: 'Submissions', icon: 'paper-plane', to: { name: 'tools-vendor-submit-wedding-my-weddings' } },
                  ]
                : [
                      { name: 'Dashboard', icon: 'home', to: { name: 'dashboard' } },
                      { name: 'Checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } },
                      { name: 'Guest List', icon: 'users', to: { name: 'tools-guest-list' } },
                      { name: 'Vendor Manager', icon: 'briefcase', to: { name: 'tools-vendor-manager' } },
                      { name: 'Mood Boards', icon: 'heart', to: { name: 'users-username-boards', params: { username: this.user.username } } },
                      {
                          name: 'Wish List',
                          icon: 'shopping-bag',
                          to: { name: 'users-username-slug', params: { username: this.user.username, slug: 'products' } },
                      },
                        this.$config.birdieEnabled ?
                          {
                            name: 'Cash Registry',
                            icon: 'gift',
                            to: { name: 'tools-cash-registry' },
                          } : {},
                      { name: '', icon: 'cog', to: { name: 'users-username-settings-type', params: { username: this.user.username, type: 'profile' } } },
                  ]
        },
    },
}
